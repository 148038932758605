
<template>
  <div class='menu-wrap fc'>
    <div v-for="(item, index) in menus"
         :key="index"
         @mouseenter="activeIndex = index"
         @mouseleave="activeIndex = -1"
         class="item pointer">
      <router-link :to="'/goods?i=' + index"
                   tag="div">
        <span v-text="item"
              class="text"></span>
        <div class="bottom"
             :class="activeUnderline(index) && 'active'"></div>
      </router-link>
    </div>

    <div @mouseenter="activeIndex = 4"
         @mouseleave="activeIndex = -1"
         class="item pointer">

      <el-dropdown placement="bottom">
        <span class="text">关于卡尔</span>
        <div class="bottom"
             :class="activeUnderline(4) && 'active'"></div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/about?type=1"
                       tag="div">
            <el-dropdown-item>品牌</el-dropdown-item>
          </router-link>
          <router-link to="/about?type=2"
                       tag="div">
            <el-dropdown-item>服务</el-dropdown-item>
          </router-link>
          <router-link to="/contact-us"
                       tag="span"
                       class="fl pointer">
            <el-dropdown-item>联系我们</el-dropdown-item>
          </router-link>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Menu',
  methods: {
    onMenu (index) {
      this.$store.commit('changeMenuIndex', index)
    }
  },
  data () {
    return {
      activeIndex: false
    }
  },
  watch: {
    $route: function (route) {
      if (route.path === '/about') {
        this.onMenu(4)
        this.activeIndex = 4
      } else if (route.path === '/goods') {
        const i = route.query.i
        this.onMenu(i)
        this.activeIndex = Number(i)
      } else {
        this.$store.commit('changeMenuIndex', -1)
      }
    }
  },
  computed: {
    menus () {
      return this.$store.state.menus
    },
    firstMenuIndex () {
      return this.$store.state.firstMenuIndex
    },
    activeUnderline () {
      return function (index) {
        return !(this.activeIndex !== index && Number(this.firstMenuIndex) !== index)
      }
    }
  }
}

</script>

<style lang='scss'>
.menu-wrap {
  height: 80px;
  .item {
    padding: 2rem 1rem;
    .text {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  .bottom {
    margin-top: 0.25rem;
    width: 100%;
    height: 0.1875rem;
    background: #c81313;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  .active {
    transform: scaleX(1) !important;
  }
}
</style>
