import Vue from 'vue'
import Vuex from 'vuex'
import dataJson from './data.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firstMenuIndex: -1,
    secondMenuIndex: -1,
    menus: dataJson.map(item => item.category)
  },
  getters: {
    secondMenus: (state) => {
      return dataJson[state.firstMenuIndex]?.children.map(item => item.category) ?? []
    },
    goods: (state) => {
      const temArr = dataJson[state.firstMenuIndex]?.children ?? []
      if (temArr.length === 0) return []
      if (state.secondMenuIndex !== -1) {
        return temArr[state.secondMenuIndex].children
      } else {
        return temArr[0].children
      }
    }
  },
  mutations: {
    changeMenuIndex (state, newIndex) {
      state.firstMenuIndex = newIndex
      state.secondMenuIndex = 0
    },
    changeSecondIndex (state, newIndex) {
      state.secondMenuIndex = newIndex
    }
  },
  actions: {
  },
  modules: {
  }
})
