<template>
  <div id="app"
       class="fbc">
    <Nav class="nav"></Nav>
    <div class="f1 fcj">
      <router-view class="content" />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
export default {
  watch: {
    $route: function (route) {
      console.log(route)
      window.scrollTo(0, 0);
    }
  },
  created () {
    // document.title = '卡尔官网'
    document.title = '时间碎片饰品'
  },
  components: { Nav, Footer }
}

</script>
<style lang="scss">
.nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
}

.content {
  max-width: 1760px;
  margin-top: 180px;
}

@media only screen and (min-width: 1080px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1080px) {
  html {
    font-size: 12px;
  }
}
@import "./styles/init.scss";
</style>
