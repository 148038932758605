<template>
  <div class='footer-wrap fc bt'>
    <div class="attention f-col">
      <span class="title bold">关注我们</span>
      <li>抖音：卡尔男生饰品</li>
      <li>天猫：时间碎片饰品旗舰店</li>
      <li>微信小程序：时间碎片轻奢饰品</li>
    </div>
    <div class="cooperation f-col">
      <span class="title bold">商务合作</span>
      <img src="../assets/cooperation.png"
           alt="商务合作">
    </div>
    <div class="join f-col">
      <span class="title bold">加入我们</span>

      <span @click="viewJobs"
            style="text-decoration:underline;cursor: pointer;margin-top: 1rem">
        查看岗位
      </span>

      <li>邮箱：liuyuhui@jxtransition.com</li>

      <span @click="viewBeian"
            style="text-decoration:underline;cursor: pointer;margin-top: 1rem">备案号：京ICP备2022009476号-1
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    viewJobs () {
      window.open('https://www.zhipin.com/gongsi/49ee68fa13bf75db1nV809W-ElA~.html?ka=search_rcmd_company_name_49ee68fa13bf75db1nV809W-ElA%7E_custompage')
    },
    viewBeian () {
      window.open('https://beian.miit.gov.cn/')
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang='scss' scoped>
@media only screen and (max-width: 1080px) {
  .footer-wrap {
    display: none;
  }
}
.footer-wrap {
  padding: 2rem 0 2.5rem;
  div {
    height: 8.125rem;
  }
  .title {
    font-size: 1rem;
  }
  .attention {
    margin-right: 8rem;
  }
  .cooperation {
    margin-right: 8rem;
    img {
      margin-top: 1rem;
      width: 6.25rem;
      height: 6.25rem;
    }
  }
  .join a {
    margin-top: 1rem;
  }
  li {
    margin-top: 1rem;
  }
}
</style>
