<template>
  <div class='nav-wrap bb'>
    <nav class="fca  relative">
      <router-link to="/contact-us"
                   tag="span"
                   class="fl pointer">
        <span class="contact">联系我们</span>
      </router-link>
      <router-link to="/"
                   tag="div"
                   class="f-col fca pointer p-center">
        <img src="@/assets/logo.png">
      </router-link>
    </nav>
    <Menu></Menu>
  </div>
</template>

<script>
import Menu from './Menu.vue'
export default {
  name: 'NavWrap',
  data () {
    return {}
  },
  components: { Menu }
}
</script>

<style lang='scss' scoped>
@media screen and (max-width: 1000px) {
  nav {
    padding: 0 50px;
    height: 100px;
    .title1 {
      font-size: 48px;
      font-style: italic;
    }
    .title2 {
      font-size: 16px;
      margin-top: 16px;
    }
    img {
      z-index: -1;
      width: 160px;
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: 1000px) {
  nav {
    padding: 0 50px;
    height: 100px;
    .title1 {
      font-size: 48px;
      font-style: italic;
    }
    .title2 {
      font-size: 16px;
      margin-top: 16px;
    }
    img {
      z-index: -1;
      width: 160px;
      margin-top: 20px;
    }
  }
}

.nav-wrap {
  z-index: 100;
  background-color: white;
  .contact {
    font-weight: 500;
  }
  .contact:hover {
    color: #c8131380;
  }
}
</style>
