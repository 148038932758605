<template>
  <div class="home">
    <img src="../assets/img2.jpg"
         class="banner"
         alt="banner">

    <div class="group fca"
         v-for="(item, index) in infos"
         :key="index">
      <img :src="item.img"
           v-if="index % 2 === 0">
      <div class="content fc">
        <div>
          <h2 class="title"
              v-animate-onscroll.repeat="'animated fadeInUp'"
              v-text="item.title"></h2>
          <div class="desc bold mt"
               v-animate-onscroll.repeat="'animated fadeInUp'"
               v-text="item.desc"></div>
          <router-link tag="li"
                       :to="item.link"
                       v-animate-onscroll.repeat="'animated fadeInUp'"
                       class="link bold mt">即刻探索</router-link>
        </div>
      </div>
      <img :src="item.img"
           v-if="index % 2 === 1">
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data () {
    return {
      infos: Object.freeze([
        {
          title: 'ACCESSORY 时尚饰品',
          img: require('../assets/img3.jpg'),
          desc: '中性风格的配饰，将时尚融入日常穿搭',
          link: '/goods?i=0'
        },
        {
          title: 'JEWELRY 珠宝',
          img: require('../assets/img5.jpg'),
          desc: '通体S925银材质的手工珠宝作品',
          link: '/goods?i=1'

        },
        {
          title: 'GLASSES 眼镜',
          img: require('../assets/img4.jpg'),
          desc: '点亮穿搭造型的利器',
          link: '/goods?i=2'

        }
      ])
    }
  },
  components: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 0 15% 6vw;
  max-width: 1440px;
  .banner {
    width: 100%;
  }
}
@media only screen and (max-width: 1080px) {
  .home {
    padding: 100px 7% 6vw;
  }
}

.group {
  margin-top: 4vw;
  img {
    border-radius: 10px;
    width: 50%;
  }
  .content {
    padding: 0 2rem;
    width: 50%;
    .mt {
      margin-top: 30px;
    }
  }
}

.title {
  font-size: 2rem;
}
.desc {
  animation-delay: 0.3s;
  font-size: 0.9rem;
}
.link {
  cursor: pointer;
  text-decoration: underline;
  animation-delay: 0.6s;
  font-size: 1rem;
}
</style>
